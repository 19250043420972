<template>
  <b-card class="card-cs-border">
    <h3 class="mb-2">Seu cadastro</h3>
    <user-card
      :user="currentUser"
      class="mt-1"
      editable
      @showEditForm="showEditForm = true"
      v-if="!showEditForm"
    />
    <profile-form v-if="showEditForm" :profile="currentUser" @cancel="showEditForm = false" />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import UserCard from '@/views/assignor/components/UserCard.vue'
import ProfileForm from '@/views/assignor/components/ProfileForm.vue'

export default {
  name: 'CurrentUser',
  components: {
    BCard,
    UserCard,
    ProfileForm,
  },
  data() {
    return {
      showEditForm: false,
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.userData
    },
  },
}
</script>

<style lang="scss">
.card-cs-border {
  border-radius: 8px;
  border: 1px solid #becad4 !important;
  box-shadow: none !important;
}

.card-cs-bg {
  border-radius: 8px;
  background: #f8fafc;
}
</style>
