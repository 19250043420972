<template>
  <div>
    <current-user />

    <created-users-list />
  </div>
</template>

<script>
import { BButton, BCard } from 'bootstrap-vue'
import CurrentUser from '@/views/assignor/components/CurrentUser.vue'
import CreatedUsersList from '@/views/assignor/components/CreatedUsersList.vue'

export default {
  name: 'Users',
  components: {
    BCard,
    BButton,
    CurrentUser,
    CreatedUsersList,
  },
  async mounted() {
    await this.getUserData()
  },
  methods: {
    async getUserData() {
      await this.$store.dispatch('auth/getUserData')
    },
  },
}
</script>

<style lang="scss">
.card-cs-border {
  border-radius: 8px;
  border: 1px solid #becad4;
}

.card-cs-bg {
  border-radius: 8px;
  background: #f8fafc;
}
</style>
