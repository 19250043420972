<template>
  <b-card class="card-cs-border mb-0 mt-2">
    <validation-observer ref="ProfileForm" #default="{ invalid }">
      <b-row>
        <b-col cols="4">
          <b-form-group label="Nome" label-for="nome">
            <validation-provider #default="{ errors }" name="nome" vid="nome" rules="required">
              <b-form-input
                id="nome"
                v-model="invite.nome"
                :state="errors.length > 0 ? false : null"
                name="nome"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Celular" label-for="telefone">
            <validation-provider
              #default="{ errors }"
              name="telefone"
              vid="telefone"
              rules="min:15"
            >
              <b-form-input
                id="telefone"
                v-model="invite.telefone"
                v-mask="['(##) #####-####']"
                :state="errors.length > 0 ? false : null"
                name="telefone"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Email" label-for="apto_para_operar">
            <validation-provider
              #default="{ errors }"
              name="email"
              vid="email"
              rules="required|email"
            >
              <b-form-input
                id="email"
                v-model="invite.email"
                :state="errors.length > 0 ? false : null"
                name="email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button
            variant="outline-primary"
            class="text-dark bg-white font-weight-bolder mr-2"
            @click="$emit('closeForm')"
          >
            Cancelar
          </b-button>
          <b-button variant="primary" :disabled="invalid">
            <span class="text-dark font-weight-bolder" @click="createInvite"> Criar convite </span>
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard, BButton, BFormGroup, BFormInput } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { mask } from 'vue-the-mask'

export default {
  name: 'InviteForm',
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  directives: { mask },
  data() {
    return {
      invite: {
        nome: '',
        telefone: '',
        email: '',
      },
    }
  },
  methods: {
    async createInvite() {
      try {
        await this.$store.dispatch('assignor/addInvite', this.invite)
        this.$swal
          .fire({
            title: 'Sucesso',
            text: 'Convite criado com sucesso',
            icon: 'success',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          })
          .then((result) => {
            this.$emit('closeForm')
          })
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
